<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="
            data.nextBackgroundColor ? data.nextBackgroundColor : null
        "
    >
        <div class="gap-x-8 py-8 md:py-20 lg:flex">
            <div
                v-if="!data.relatedFaqs"
                class="relative mb-12 md:mb-0 lg:w-1/4"
            >
                <div class="lg:top-32">
                    <div class="mb-6 text-xl font-bold">Table of Contents</div>
                    <div class="flex flex-col">
                        <div
                            v-for="category in categories"
                            :key="'category-menu-' + category.id"
                        >
                            <template v-if="category.cards.length">
                                <div
                                    class="border-gray-[#BDBDBD] mb-2 border-b py-2"
                                >
                                    <Collapse
                                        icon="chevron"
                                        :is-expanded="category.expanded"
                                        class="mb-2"
                                        content-class="pt-4"
                                        :title-is-clickable="true"
                                    >
                                        <template #title-link>
                                            <a
                                                :href="'#' + category.id"
                                                class="text-grind hover:text-brew"
                                            >
                                                <Icon
                                                    name="arrow-down-to-line-solid"
                                                    class="text-[21px]"
                                                />
                                            </a>
                                        </template>

                                        <template #title>
                                            <h4
                                                class="text-grind no-underline group-hover:text-brew"
                                            >
                                                {{ category.heading }}
                                            </h4>
                                        </template>

                                        <div
                                            v-for="question in category.cards"
                                            :key="'toc-faq-' + question.id"
                                            class="flex items-center justify-between py-4 no-underline hover:cursor-pointer hover:text-brew"
                                            :class="[
                                                selectedFaq ===
                                                '#' + question.id
                                                    ? 'text-brew'
                                                    : 'text-grind',
                                            ]"
                                            @click="setSelectedFaq(question.id)"
                                        >
                                            <div>
                                                {{ question.title }}
                                            </div>
                                            <Icon
                                                name="arrow-down-solid"
                                                class="text-[21px]"
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="[data.relatedFaqs ? 'lg:w-full' : 'lg:w-3/4']">
                <template
                    v-for="(category, index) in data.categories"
                    :key="'faq-category-' + index"
                >
                    <div
                        v-if="category.cards.length"
                        :class="[
                            index !== 0 ? 'py-12' : 'pb-12',
                            index !== data.categories.length - 1
                                ? 'border-b border-gray-300'
                                : '',
                        ]"
                    >
                        <FaqCategoryGroup
                            :data="category"
                            :action-button="data.actionButton"
                            :related-faqs="data.relatedFaqs"
                            :selected-faq="selectedFaq"
                        />
                    </div>
                </template>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import BlockContainer from '~/components/BlockContainer.vue';
import FaqCategoryViewType from '~/types/FaqCategoryViewType';
import FaqCategoryGroup from '~/components/page-building/faq/FaqCategoryGroup.vue';
import FaqCardGroupType from '~/types/FaqCardGroupType';
import { scrollToId } from '~/utils/helpers';
import CollapseCardType from '~/types/CollapseCardType';

const route = useRoute();
const selectedFaq = ref('');
const categories = ref<FaqCardGroupType[]>([]);

const props = defineProps<{
    data: FaqCategoryViewType;
}>();

const setSelectedFaq = (questionId: string) => {
    selectedFaq.value = '';
    setTimeout(() => {
        scrollToId(questionId);
        selectedFaq.value = '#' + questionId;
        window.location.hash = '#' + questionId;
    }, 300);
};

onMounted(() => {
    selectedFaq.value = route.hash ? route.hash : '';
    categories.value = props.data.categories;

    Object.values(categories.value).forEach((category: FaqCardGroupType) => {
        Object.values(category.cards).forEach((card: CollapseCardType) => {
            if ('#' + card.id === selectedFaq.value) {
                category.expanded = true;
            }
        });
    });
});
</script>
