<template>
    <FaqCategoryView v-editable="blok" :data="faqCategoryViewData" />
</template>

<script setup lang="ts">
import FaqCardGroupType from '~/types/FaqCardGroupType';
import FaqCategoryView from '~/components/page-building/faq/FaqCategoryView.vue';
import FaqCategoryViewType from '~/types/FaqCategoryViewType';
import ButtonType from '~/types/ButtonType';
import { capitalizeFirstLetter, slugify } from '~/utils/helpers';
import StoryblokClient from 'storyblok-js-client';
import CollapseCardType from '~/types/CollapseCardType';
import { richTextToHtml } from '~/utils/storyblok';
import { useFAQStructuredData } from '~/composables/useStructuredData';

const config = useRuntimeConfig();

const client = new StoryblokClient({
    accessToken: config.public.storyblok.accessToken,
    region: 'us',
});

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const faqsResponse = await client.get(`cdn/stories`, {
    per_page: 100,
    by_slugs: 'faqs/*',
    filter_query: {
        component: { in: 'faq' },
    },
});

const faqData = faqsResponse.data;

const faqCategoryViewData = computed<FaqCategoryViewType>(() => {
    const categories = props.blok.categories
        ? props.blok.categories.map((category: any) => {
              const cards: CollapseCardType[] = [];

              Object.values(faqData.stories).forEach((faq: any) => {
                  if (
                      faq.content.category &&
                      faq.content.category.includes(category)
                  ) {
                      cards.push({
                          title: faq.content.question,
                          content: richTextToHtml(faq.content.answer) as string,
                          id: faq.slug,
                          vEditable: faq,
                      } as CollapseCardType);
                  }
              });
              return {
                  heading: capitalizeWords(category.replace(/-/g, ' ')),
                  id: slugify(category),
                  cards: cards,
                  expanded: false,
              } as FaqCardGroupType;
          })
        : [];

    const structuredData = useFAQStructuredData(categories);

    useServerHead(() => ({
        script: structuredData,
    }));

    return {
        categories,
        relatedFaqs: props.blok.related_faqs,
        actionButton:
            props.blok.action_button?.length > 0
                ? ({
                      label: props.blok.action_button[0].label,
                      style: props.blok.action_button[0].style,
                      url: props.blok.action_button[0].link.cached_url,
                      vEditable: props.blok.action_button[0],
                  } as ButtonType)
                : null,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
    } as FaqCategoryViewType;
});
</script>
